* {
  font-family: arial, sans-serif;
}


html, body {
  padding: 0;
  margin: 0
}

.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header, .footer, .content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header, .footer {
  background-color: yellow;
}

.content {
  flex: 1;
}

.container {
  width: 100%;
  min-width: 200px;
  max-width: 600px;
}